import React from 'react'
import { Row, Col } from 'react-flexbox-grid'
import Layout from '../components/dfa-theme/layout'
import BrandCTA from '../components/brand-cta'
import { withPrefix } from "gatsby"
import ExternalLink from '../components/external-link'
import ActiveLink from '../components/active-link'
import Hero from '../components/hero'
import AccordianExample from '../components/accordian-example'
import VideoContainer from '../components/video-container'
import VideoCollection from '../lib/videos/consumer-videos'

const metaTags = {
  description: 'Caregivers can learn about SUPPRELIN® LA (histrelin acetate) safety and side effects, features and risks, including full Prescribing Information.',
  keywords: 'Homepage',
  title: 'Home | Patient'
}

const externalLinksMetaTags = {
  category: 'External Link',
  action: 'Click',
  label: 'Deerfield Homepage',
}

const IndexPage = () => (

  <Layout meta={metaTags}>
    <Row>
      <Col xs={12}>
      <Hero/>
      </Col>
    </Row>
    <Row between="xs">
      <Col xs={12} md={6}>
        <BrandCTA
          href='/patients/caregiver-signup'
          LinkCaption="Order an all-in-one caregiver's kit for information on SUPPRELIN® LA and CPP"
        >
          Order an all-in-one caregiver's kit for information about SUPPRELIN<sup>&reg;</sup> LA and CPP
        </BrandCTA>
      </Col>
      <Col xs={12} md={6}>
        <BrandCTA
          href="/patients/caregiver-resources/#video=0"
          LinkCaption="Hear from a mother – and nurse – whose child
          has been treated with SUPPRELIN<sup>&reg;</sup> LA - homepage"
        >
          Hear from a mother – and nurse – whose child
          has been treated with SUPPRELIN<sup>&reg;</sup> LA

        </BrandCTA>
      </Col>
    </Row>
    <VideoContainer url={VideoCollection[1].iframeUrl} smallThumb active className={'video-thumb'} carousel directLink={false} videoId={VideoCollection[1].wistiaID} poster=''></VideoContainer>
    <VideoContainer url={VideoCollection[2].iframeUrl} smallThumb active className={'video-thumb'} carousel directLink={false} videoId={VideoCollection[2].wistiaID} poster=''></VideoContainer>
    <VideoContainer url={VideoCollection[2].iframeUrl} smallThumb active className={'video-thumb'} carousel directLink={false} videoId={VideoCollection[3].wistiaID} poster=''></VideoContainer>
  </Layout>
)

export default IndexPage
